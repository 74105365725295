import React, { useState } from 'react'
import { Box, Grid, Typography, Button, Select, MenuItem, InputAdornment, IconButton, TextField, Divider } from "@mui/material";
import logo from '../Images/SingleLogo.svg';
import facebook from '../Images/Facebook.svg';
import twitter from '../Images/Twitter.svg';
import instagram from '../Images/Instagram.svg';
import youtube from '../Images/Youtube.svg';
import galleryImage from '../Images/mainNews.png';
import tikTok from '../Images/tiktok.svg';

import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const location = useLocation();
    const currentPath = location.pathname;


    return (
        <Box pt={4}>
        <Grid container spacing={4} className='opacity1' sx={{ backgroundColor: '#000', height: 'auto', px: { md: 15, xs: 2 } }}>
            <Grid item md={3} xs={12} >
                <Box width={'80%'}>
                <img src={logo} height='30%' style={{ margin: 'auto', display: 'block', }} />
                <Typography className='h2 bold text-white' my={1}>Miiho News</Typography>
                <Typography className='text3 light text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Neque, pellentesque dictum posuere id diam rutrum.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
                    <Link to="#link1" target='_blank'>
                        <img src={tikTok} alt="Image 1" width="38px" height="38px" style={{margin:'0px 5px'}} />
                    </Link>
                    <Link to="#link2" target='_blank'>
                        <img src={twitter} alt="Image 2" width="38px" height="38px" style={{margin:'0px 5px'}} />
                    </Link>
                    <Link to="#link3" target='_blank'>
                        <img src={instagram} alt="Image 3" width="38px" height="38px" style={{margin:'0px 5px'}} />
                    </Link>
                </Box>
                </Box>
            </Grid>

            <Grid item md={3} xs={12}>
                <Typography className='h2 mid text-white'>Photo gallery</Typography>
                <Grid container my={2}>
                    <Grid item md={4}>
                        <Divider className='primary-red' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                    <Grid item md={8}>
                        <Divider className='primary-white' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={4} xs={4}  >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>

                    <Grid item md={4} xs={4}  >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>

                    <Grid item md={4} xs={4} >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>
                    <Grid item md={4} xs={4} >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>

                    <Grid item md={4} xs={4} >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>

                    <Grid item md={4} xs={4}  >
                        <img className='image' src={galleryImage} alt="Image 4" width="100%" height="100%" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item md={3} xs={12}>
                <Typography className='h2 mid text-white'>Tags</Typography>
                <Grid container my={2}>
                    <Grid item md={4}>
                        <Divider className='primary-red' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                    <Grid item md={8}>
                        <Divider className='primary-white' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                </Grid>
                <Typography className='text3 text-white primary-orange mid' sx={{ display: 'inline', p: 0.5, m: 0.5 }}>
                    Football
                </Typography>
                <Typography className='text3 text-white primary-orange mid' sx={{ display: 'inline', p: 0.5, m: 0.5 }}>
                    Football
                </Typography>

            </Grid>
            <Grid item md={3} xs={12}>
                <Typography className='h2 mid text-white'>Stay In Touch</Typography>
                <Grid container my={2}>
                    <Grid item md={4}>
                        <Divider className='primary-red' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                    <Grid item md={8}>
                        <Divider className='primary-white' sx={{ height: '2px', opacity: '1', }} />
                    </Grid>
                </Grid>

                <Box>
                    <Typography className='text3 light text-white'>
                        To be updated with all the latest news, offers
                        and special annoucements.
                    </Typography>
                    <form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                gap: 2,
                my:3
              }}
            >
              <input
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "none",
                  outline: "none",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "19px",
                }}
                type="email"
                placeholder="Your email address"
              />
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Work Sans",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "29px",
                  bgcolor: "#CC0000",
                  borderRadius: "0px",
                }}
              >
                Subscribe
              </Button>
            </Box>
          </form>
                </Box>


            </Grid>
        </Grid>
        </Box>
    )
}

export default Footer