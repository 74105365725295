import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';

export default function SubHeading({name}) {


  return (
    <>
       <div className='Subheading'>
        <div className='heading-subtitle'>
            <h6>{name}</h6>
        </div>
        </div>
        <LinearProgress  color="error" variant="determinate"  sx={{height:'1px',bgcolor:'#393939'}} value={50}/>
    </>
  )
}
