import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CoreValuesCard from './CoreValuesCard'

export default function OurCoreValues() {
  return (
    <>
    <Box my={5}>
    <Typography className='h1 bold'  sx={{ my:4}} >
          Our Core Values
        </Typography>
        <Box>
            <Grid container spacing={4}>
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
                <Grid item md={5.5}>
                  <CoreValuesCard/>
                </Grid>
               
         
            </Grid>
        </Box>

    </Box>
    </>
  )
}
