import React, { useState } from "react";
import Layout from "../Components/Layout";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const { world } = useSelector((state) => ({ ...state }));

    function getHoursDifferenceFromDate(date) {
        // Get the current date and time
        const currentDate = new Date();
      
        // Convert the input date parameter to a Date object
        const inputDate = new Date(date);
      
        // Calculate the time difference in milliseconds
        const timeDifference = currentDate - inputDate;
      
        // Convert the time difference from milliseconds to hours
        const hoursDifference = timeDifference / (1000 * 60 * 60);
      
        return hoursDifference;
      }

      const handleSubmit = (e) => {
        e.preventDefault();
        if(!fullName || !email || !phoneNumber || !message){
            enqueueSnackbar("please enter all values", {variant:'warning'});
        }else{

        }
      }

    return (
        <Layout>
            <Grid container sx={{ mt: { md: 4, xs: 0 }, mx: { md: 15, xs: 3 }, width: '80%', mb: 2 }}>
                <Grid item md={8}>
                    <Typography className='h1 bold active' sx={{ mt: 4 }} >
                        Contact Us
                    </Typography>
                    <Typography className='h2 sub-bold' sx={{ my: 2 }} >
                        Social media and email
                    </Typography>
                    <Typography className="h2 sub-light" sx={{ my: 2, color: '#000' }}>
                        Our email address is news@mihotv.co.in. You can <br />
                        get in touch through Miho TV News Facebook page<br />
                        or find us on Twitter - we're @MihotvNews
                    </Typography>
                    <Typography className="h2 bold active" sx={{ mt: 4, mb: 2 }}>
                        Get in touch
                    </Typography>
                    <input type="text" className="contact-input work-sans" placeholder="Full Name" 
                    value={fullName} onChange={(e) => setFullName(e.target.value)} />
                    <br />
                    <input type="email" className="contact-input work-sans" placeholder="Your Email" 
                    value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <br />
                    <input type="number" className="contact-input work-sans" placeholder="Phone Number" 
                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                    <br />
                    <textarea className="contact-input work-sans"
                        placeholder="Write your message"
                        style={{ resize: 'none' }}
                        rows={5} cols={5} 
                        value={message} onChange={(e) => setMessage(e.target.value)}/>
                    <Typography className="contact-us-button" onClick={handleSubmit}>
                        Send
                    </Typography>
                </Grid>
                <Grid item md={4} className="mHide">
                    <Typography className='h1 bold active' sx={{ mt: 4 }} >
                        Popular This week
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={8}>
                        {
                            world && world?.length && world?.slice(0, 5)?.map((item, index) => (
                                <>
                                    <Grid item md={4}>
                                        <img src={item?.images?.original} style={{ width: '140px', height: '104px', objectFit: 'cover' }} />
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography className="text2">
                                            World {getHoursDifferenceFromDate(new Date(item?.created_at)) < 24 ? `${parseInt(getHoursDifferenceFromDate(new Date(item?.created_at)))} hours ago`  : `${Math.ceil(getHoursDifferenceFromDate(new Date(item?.created_at)) / 24)} days ago`}
                                        </Typography>
                                        <Typography className="h3 sub-bold">
                                            <Link to={`/detailspage/${encodeURIComponent(item?.newsUrl)}`} className="link">
                                            {item?.title.length > 40 ? `${item?.title?.substring(0, 40)}...`: item?.title}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default ContactUs;