import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { businessReducer } from './businessReducer';
import { topNewsReducer } from './topNewsReducer';
import { sportsReducer } from './sportsReducer';
import { lifestyleReducer } from './lifestyleReducer';
import { worldReducer } from './worldReducer';
import { nationalReducer } from './nationalReducer';
import { entertainmentReducer } from './entertainmentReducer';
import { technologyReducer } from './technologyReducer';
import { scienceReducer } from './scienceReducer';
import { radioReducer } from './radioReducer';
import { healthReducer } from './healthReducer';


const rootReducer = combineReducers({
  slice1: authReducer,
  topnews:topNewsReducer,
  business: businessReducer,
  sports:sportsReducer,
  lifestyle:lifestyleReducer,
  world:worldReducer,
  national:nationalReducer,
  entertainment:entertainmentReducer,
  technology:technologyReducer,
  science:scienceReducer,
  radio:radioReducer,
  health: healthReducer,
});

export default rootReducer;