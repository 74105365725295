import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const MainVideoCard = ({ cover, catagory, title, authorName, time, url }) => {
  return (
    <>
      <Box >
        <Link to={`/detailspage/${encodeURIComponent(url)}`}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "250px",
                    zIndex: -1,
                    position: "relative",
                    height: { md: '70vh', xs: '40vh' }
                  }}
                >
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={cover}
                    alt=""
                  />
                  <Typography
                    sx={{
                      position: "absolute",
                      px: 1,
                      backgroundColor: "#0b9931",
                      top: 15,
                      left: 15,
                      fontSize: "12px",
                      color: "#fff",
                    }}
                  >
                    {catagory}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                    }}
                  >
                    <BsPlayCircle size={80} />
                  </Box>
                  <Box
                    className="banner-text"
                    sx={{
                      position: "absolute",
                      bottom: "40px",
                      left: '30px',
                      width: "80%",
                      transition: "0.4s ease",
                      display: { md: 'block', xs: 'none' }


                    }}
                  >
                    <Box className="text-white">
                      <Typography className="subText1 mid text-white">
                        Craig Bator -{" "}
                        <span className="opacity2s">27 Dec 2020 </span>{" "}
                      </Typography>
                    </Box>
                    <Typography
                      className="h1"
                      sx={{ color: "#fff", width: "70%" }}
                    >
                      {title}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Link>
      </Box>
    </>
  );
};

export default MainVideoCard;
