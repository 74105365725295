import React, { useState } from 'react'
import { Box, Typography, Button, MenuItem } from "@mui/material";
import logo from '../Images/Logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowForwardIos } from '@mui/icons-material';

function Navbar() {
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        navigate("/search/"+searchQuery);
    }

    const handleKeyPress = (event) => {
        // Check if the pressed key is Enter (key code 13)
        if (event.key === 'Enter') {
            handleSearch(event);
        }
      };

    return (
        <Box sx={{ backgroundColor: '#fff', boxShadow: 'none', width: '100%', px: 15, display: { md: 'block', xs: 'none', }, }}>
            <Box sx={{ display: { md: 'flex', xs: 'block' }, justifyContent: 'space-between' }}>
                <Link to="/">
                    <img src={logo} height='100%' />
                </Link>
                <Box className='center' sx={{ display: { md: 'block', xs: 'flex' }, justifyContent: 'space-between' }}>
                    <Link to="/miho-tv">
                        <Button className='main-button text1' sx={{ mx: 2 }}>
                            Miiho TV
                        </Button>
                    </Link>
                    <Link to="/radio-live">
                        <Button className='main-button text1 '>
                            Miiho Radio
                        </Button>
                    </Link>
                </Box>
            </Box>

            <Box className='primary-gray cursor' sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: 'space-between', alignItems: "center", my: 2, }}>
                {/* {Links.map((item) => ( */}
                <Link className='text-white text3 mid' to="/">
                    <Box p={2} className={currentPath === "/" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid'>Home</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/world">
                    <Box px={2} py={2} className={currentPath === "/news-list/world" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid'>International</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/sports">
                    <Box px={2} py={2} className={currentPath === "/news-list/sports" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid'>Sports</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/business">
                    <Box px={2} py={2} className={currentPath === "/news-list/business" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid' >Buisiness</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/technology">
                    <Box px={2} py={2} className={currentPath === "/news-list/technology" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid'>Technology</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/entertainment">
                    <Box px={2} py={2} className={currentPath === "/news-list/entertainment" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid' >Entertainment</Typography>
                    </Box>
                </Link>
                <Link className='text-white text3 mid' to="/news-list/lifestyle">
                    <Box px={2} py={2} className={currentPath === "/news-list/lifestyle" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid' >Lifestyle</Typography>
                    </Box>
                </Link>
                <Box className={currentPath === "" ? 'primary-red' : ''}>
                    {/* <Link className='text-white h3 mid' to="">Pages</Link> */}
                    <div>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            disableRipple
                            endIcon={<KeyboardArrowDownIcon />}
                            className='text-white text3 mid'
                            sx={{ textTransform: 'none', }}

                        >
                            Pages
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }} onClick={() => navigate("/contact-us")}>Contact us <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }} onClick={() => navigate("/about-us")}>About us <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Archive <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Author <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Photo Gallery <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                            <MenuItem className='dropdown-item' onClick={handleClose}>
                                <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px' }}>Video <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                            </MenuItem>
                        </Menu>
                    </div>

                </Box>
                <Link className='text-white text3 mid' to="/about-us">
                    <Box px={2} py={2} className={currentPath === "/about-us" ? 'primary-red' : ''}>
                        <Typography className='text-white text3 mid' >About us</Typography>
                    </Box>
                </Link>
                {/* ))} */}
                <Box sx={{ color: '#fff', opacity: .7, borderLeft: '1px solid #000', width: '170px' }}>
                    <input type="text" className='primary-gray'
                        style={{ height: '100%', width: '70%', border: 'none', outline: 'none', padding: '10px', color:'#BCBCBC' }}
                        placeholder="Search for" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyUp={handleKeyPress}/>
                    <SearchIcon sx={{cursor:'pointer'}} onClick={handleSearch}/>
                </Box>

                {/* <Select
                    sx={{ border: 'none' }}
                    value={currentPath}
                    onChange={(e) => {
                        // Redirect to the selected page when the dropdown value changes
                        window.location.href = e.target.value;
                    }}
                >
                    {Links.map((item) => (
                        <MenuItem key={item.path} value={item.path}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select> */}

                {/* <input
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    variant="outlined"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    placeholder="Search for"
                    InputProps={{
                        endAdornment: (
                            <SearchIcon className='text-white' />
                        ),
                        disableRipple: true,

                    }}
                /> */}
            </Box>



        </Box>

    )
}

export default Navbar