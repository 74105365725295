import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import img from "../../Images/socialAd.png";
import img1 from "../../Images/Videomain.png";
import { FaRegCommentAlt, FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import "vidstack/styles/defaults.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "vidstack/styles/community-skin/video.css";

import {
  MediaCommunitySkin,
  MediaOutlet,
  MediaPlayer,
  MediaPoster,
} from "@vidstack/react";

export default function VideoDetails() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <Box my={5}>
        <Box data-aos="fade-left">
          <Box>
            <Box>
              <Typography className="subText2 mid">
                Entertainment -{" "}
                <span style={{ opacity: 0.6 }}> 27 Dec 2020</span>
              </Typography>
            </Box>
            <Typography className="h1 sub-bold">
              Play This Game for Free In this Epic Store, This Weekend
            </Typography>
            <Stack
              direction="row"
              sx={{ alignItems: "center", mt: 1 }}
              spacing={2}
            >
              <Avatar alt="" src={img} sx={{ width: 45, height: 45 }} />

              <Typography className="reporterName">
                Cameron Williamson
              </Typography>
            </Stack>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3, mt: 5 }}>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FaRegHeart color="#109BE9" fontSize="1.5em" />
                <Typography>521K</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FaRegCommentAlt color="#109BE9" fontSize="1.5em" />
                <Typography>213</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FiShare2 color="#109BE9" fontSize="1.5em" />
                <Typography>80</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={2}>
          <MediaPlayer
            title="Sprite Fight"
            src="https://media-files.vidstack.io/720p.mp4"
            poster={img1}
           
            aspectRatio={16 / 9}
            crossorigin=""
          >
            <MediaOutlet>
              <MediaPoster alt="img" />
            </MediaOutlet>
            <MediaCommunitySkin />
          </MediaPlayer>

          <Box sx={{ color: "#515151" }}>
            <Typography className="text2" mt={3}>
              Leverage agile frameworks to provide a robust synopsis for high
              level overviews. Iterative approaches to corporate strategy foster
              collaborative thinking to further the overall value proposition.
              Organically grow the holistic world view of disruptive innovation
              via workplace diversity and empowerment. Bring to the table
              win-win survival strategies to ensure proactive domination. At the
              end of the day, going forward, a new normal that has evolved from
              generation X is on the runway heading towards a streamlined cloud
              solution. User generated content in real-time will have multiple
              touchpoints for offshoring. Capitalize on low hanging fruit to
              identify a ballpark value added activity to beta test. Override
              the digital divide with additional clickthroughs from DevOps.
              Nanotechnology immersion along the information highway will close
              the loop on focusing solely on the bottom line. Leverage agile
              frameworks to provide a robust synopsis for high level overviews.
              Iterative approaches to corporate strategy foster collaborative
              thinking to further the overall value proposition. Organically
              grow the holistic innovation via workplace diversity and
              empowerment. Bring to the table win-win survival strategies to
              ensure proactive domination. At the end of the day, going forward,
              a new normal that has evolved from generation X is on the runway
              heading towards a streamlined cloud solution. content in real-time
              will have multiple touchpoints for offshoring. Capitalize on low
              hanging fruit to identify a ballpark value added activity to beta
              test. Override the digital divide with additional clickthroughs
              from DevOps. Nanotechnology immersion along the information
            </Typography>
            <Typography className="text2" mt={3}>
              Leverage agile frameworks to provide a robust synopsis for high
              level overviews. Iterative approaches to corporate strategy foster
              collaborative thinking to further the overall value proposition.
              Organically grow the holistic world view of disruptive innovation
              via workplace diversity and empowerment.
            </Typography>
            <Typography className="text2" mt={3}>
              Leverage agile frameworks to provide a robust synopsis for high
              level overviews. Iterative approaches to corporate strategy foster
              collaborative thinking to further the overall value proposition.
              Organically grow the holistic world view of disruptive innovation
              via workplace diversity and empowerment. Leverage agile frameworks
              to provide a robust synopsis for high level overviews. approaches
              to corporate strategy foster collaborative thinking to further the
              overall value proposition. Organically grow the holistic world
              view of disruptive innovation via workplace diversity and
              empowerment.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
