import { Box, Grid, Skeleton } from "@mui/material";
import React from "react";
import BannerCard from "./cards/BannerCard";
import { useSelector } from "react-redux";


export default function HomeBannerBanner() {

  const { topnews } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item md={7} xs={12}>
            <Box sx={{ height: { md: "100%", xs: "45vh" } }}>
              {topnews.length > 0 ? (
                <BannerCard
                  cover={topnews[0]?.images?.original}
                  category="Latest"
                  categoryCss="category1"
                  title={topnews[0]?.title}
                  classname="bannerHeading1"
                  date={topnews[0]?.timestamp}
                  authorName={topnews[0]?.publisher}
                  desc={topnews[0]?.snippet}
                  url={topnews[0]?.newsUrl}
                />
              ) : (
                <Skeleton variant="rectangular" sx={{ height: { md: "100%", xs: "45vh" } }} />
              )}

            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <Grid container spacing={2} direction="column">
              <Grid item md={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={6}>
                    <Box sx={{ height: { md: "45vh", xs: "30vh" } }}>
                      {topnews.length > 0 ? (
                        <BannerCard
                          cover={topnews[1]?.images?.original}
                          category="Latest"
                          categoryCss="category1"
                          title={topnews[1]?.title.length > 30 ? `${topnews[1]?.title?.substring(0, 30)}...` : topnews[1]?.title}
                          classname="bannerHeading2"
                          date={topnews[1]?.timestamp}
                          authorName={topnews[1]?.publisher}
                          desc={topnews[1]?.snippet}
                          url={topnews[1]?.newsUrl}
                        />

                      ) : (
                        <Skeleton variant="rectangular" sx={{ height: { md: "45vh", xs: "30vh" } }} />
                      )}

                    </Box>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <Box sx={{ height: { md: "45vh", xs: "30vh" } }}>
                      {topnews.length > 0 ? (
                        <BannerCard
                          cover={topnews[2]?.images?.original}
                          category="Latest"
                          categoryCss="category1"
                          title={topnews[2]?.title.length > 30 ? `${topnews[2]?.title?.substring(0, 30)}...` : topnews[2]?.title}
                          classname="bannerHeading3"
                          date={topnews[2]?.timestamp}
                          authorName={topnews[2]?.publisher}
                          desc={topnews[2]?.snippet}
                          url={topnews[2]?.newsUrl}
                        />

                      ) : (
                        <Skeleton variant="rectangular" sx={{ height: { md: "45vh", xs: "30vh" } }} />
                      )}

                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ height: "45vh" }}>
                  {topnews.length > 0 ? (
                    <BannerCard
                      cover={topnews[3]?.images?.original}
                      category="Latest"
                      categoryCss="category1"
                      title={topnews[3]?.title.length > 50 ? `${topnews[3]?.title?.substring(0, 50)}...` : topnews[3]?.title}
                      classname="bannerHeading4"
                      date={topnews[3]?.timestamp}
                      authorName={topnews[3]?.publisher}
                      desc={topnews[3]?.snippet}
                      url={topnews[3]?.newsUrl}
                    />
                  ) : (
                    <Skeleton variant="rectangular" sx={{ height: "45vh" }} />
                  )}

                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
