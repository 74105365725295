import React, { useEffect } from "react";
import Heading from "../common/Heading";
import "../../styles/heading.css";
import { Box, Grid, Skeleton } from "@mui/material";
import VideoCard from "../common/VideoCard";
import img1 from '../../Images/Video1.png'
import img2 from '../../Images/Video2.png'
import img3 from '../../Images/Video3.png'
import mainimg from '../../Images/Videomain.png'
import StayConnected from "./StayConnected";
import MainVideoCard from "./cards/MainVideoCard";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from "react-redux";

export default function VedioSection() {
    const { entertainment, sports } = useSelector((state) => ({ ...state }))

    const Headings = [
        "All", "Travel", "Recepies", "Health & Fitness", "Music"

    ]

    const handlePageChange = (event, page) => {
        // Handle the page change here if needed
    };
    useEffect(() => {
        AOS.init();
    }, [])

    const entertainmentVideo = entertainment;
    const sportsVideo = sports;

    return (
        <>
            <Grid data-aos="fade-left" container spacing={2}>
                <Grid item md={9} gap={2}>
                    <Box my={5}  >
                        <Heading name="Video" />
                        <Grid container spacing={2}>
                            <Grid item md={12} my={2}>
                                {entertainmentVideo.length > 0 ? (

                                    <MainVideoCard
                                        cover={entertainmentVideo[0].images?.original}
                                        title={entertainmentVideo[0].title}
                                        catagory="entertainment"
                                        authorName={entertainmentVideo[0].publisher}
                                        time={entertainmentVideo[0]?.timeline}
                                        url={entertainmentVideo[0]?.newsUrl}
                                    />
                                ) : (
                                    <Box>
                                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '300px', }} />
                                    </Box>
                                )}

                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {sportsVideo.length > 0
                                ? sportsVideo
                                    .slice(0, 3)
                                    .map((data) => (

                                        <Grid item md={4}>
                                            <VideoCard
                                                cover={data?.images?.original}
                                                title={data?.title}
                                                catagory="Sports"
                                                authorName={data?.publisher}
                                                time={data?.timeline}
                                                url={data?.newsUrl}
                                            />
                                        </Grid>
                                    ))
                                :
                                <Grid container spacing={2} mt={1} ml={.5}>
                                    <Grid item md={4}>
                                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '200px', }} />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '200px', }} />
                                    </Grid>
                                    <Grid item md={4}>
                                        <Skeleton variant="rectangular" sx={{ width: '100%', height: '200px', }} />
                                    </Grid>
                                </Grid>
                            }

                        </Grid>
                    </Box>
                </Grid>
                <Grid item md={3}>
                    <StayConnected />
                </Grid>
            </Grid>

        </>
    );
}
