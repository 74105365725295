import React, { useState, useRef, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BsPauseCircle, BsPlayCircle } from "react-icons/bs";
import ReactHlsPlayer from 'react-hls-player';

const VideoCard2 = ({ cover, catagory, title, authorName, time, url, index }) => {
    const [currentIndex, setCurrentIndex] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleTogglePlay = () => {
        setCurrentIndex(index);
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const videoPlayer = document.getElementById(index);
        if (videoPlayer) {
            if (isPlaying) {
                videoPlayer.play();
            } else {
                videoPlayer.pause();
            }
        }
    }, [isPlaying]);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleTogglePlay}>
                        <Box sx={{ width: '100%', height: '250px', zIndex: -1, position: 'relative' }}>
                            <img style={{ height: '250px', width: '100%' }} src={cover} alt='' />
                            <Typography sx={{ position: 'absolute', px: 1, backgroundColor: '#0b9931', top: 15, left: 15, fontSize: '12px', color: '#fff' }}>{catagory}</Typography>
                            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff' }}>
                                {currentIndex === index && isPlaying ? <BsPauseCircle size={40} /> : <BsPlayCircle size={40} />}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <Box>
                        <div>
                            <Typography className="subText2">{authorName} - <span className="opacity2"> {time} </span> </Typography>
                        </div>
                        <Typography className="text1" > {title} </Typography>
                        <div ></div>
                    </Box>
                </Grid>
            </Grid>

            {url &&
                <ReactHlsPlayer
                    id={index}
                    src={url}
                    width="100%"
                    height="auto"
                    onPlay={handlePlay}
                    onPause={handlePause}
                    style={{ display: 'none' }}
                />
            }
        </Box>
    );
};

export default VideoCard2;
