import { Box, Typography } from "@mui/material";
import React from "react";

export default function CoreValuesCard() {
  return (
    <>
      <Box className="trn-2" sx={{boxShadow: '0px 0px 10px 0px #3D385533'}}>
        <Box p={4}>
          <Typography sx={{fontFamily: "Oswald", fontWeight: 700, fontSize: "1.125rem", color:'#2E7CCC', lineHeight:'20px'}}>Our Culture</Typography>
          <Typography sx={{fontFamily: "Oswald", fontWeight: 700, fontSize: "1.5rem", lineHeight:'24px', color:'#1A1D26', width:'60%', pt:1}}>Mission Driven and Innovative</Typography>
          <Typography sx={{fontFamily: "Work Sans", fontWeight: 400, fontSize: "14px",lineHeight:'24px', color:'#1A1D26',width:'90%', pt:2}}>
            Our mission of helping you make the best decisions is our north
            star, guiding us to always serve your interests. It drives
            everything we do. And although we are a nearly 90-year-old company,
            we’ve been a leader in digital journalism for more than a decade. We
            operate with the mentality of a startup, stressing a flat management
            structure and a team-based operating approach.
          </Typography>
        </Box>
      </Box>
    </>
  );
}
