import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import OurPlatformsCard from './OurPlatformsCard'

export default function OurPlatforms() {
  return (
    <>
    <Box my={10}>
    <Typography className='h1 bold' sx={{  my:4}} >
          Our Platforms
        </Typography>
        <Box>
            <Grid container spacing={4}>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
                <Grid item md={6}>
                  <OurPlatformsCard/>
                </Grid>
            </Grid>
        </Box>

    </Box>
    </>
  )
}
