import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import SubHeading from '../common/SubHeading'
import ResourcesCard from './ResourcesCard'
import adImg from '../../Images/socialAd.png'

export default function Resources() {
  return (
    <>
    <Box my={5}>
       <SubHeading name="Resources"/>
       <Grid container spacing={1} mt={2}>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          <Grid item md={12} sm={6} xs={12}><ResourcesCard/></Grid>
          </Grid>
          <Box sx={{position:'relative', zIndex:-1, mt:{md:9, xs:2}}}>
            <img src={adImg} style={{width:'100%'}} alt='img'/>
            <Typography sx={{position:'absolute',top:'50%', left:'50%',transform: 'translate(-50%, -50%)' , color:'#fff', fontSize:'24px'}}>Ad</Typography>
            </Box>

    </Box>
    </>
  )
}
