import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Box, } from "@mui/material";
import Topbar from './common/Topbar'
import FollowUs from './common/FollowUs';
import MobileNavbar from './MobileNavbar';

function Layout({ children }) {
    return (
        <Box sx={{overflowX:"hidden"}}>
            <Topbar />
            <Navbar />
            <MobileNavbar />
            <Box>
                {children}
            </Box>
            <FollowUs />
            <Footer />
        </Box>
    )
}

export default Layout