import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import { Box, Grid, Skeleton, Typography, } from '@mui/material'
import SearchNews from '../Components/miho search/SearchNews'
import SearchVideos from '../Components/miho search/SearchVideos'
import PurchaseNowCard from '../Components/common/PurchaseNowCard'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import SearchNewsCard from '../Components/miho search/SearchNewsCard'

export default function MihoSearch() {
  const [news, setNews] = useState([]);
  const { search } = useParams();
  const { entertainment, lifestyle, world, business, sports, technology, science, topnews } = useSelector((state) => ({ ...state }));


  function getUniqueObjects(arr, prop) {
    const seen = new Set();
    return arr.filter((obj) => {
      const value = obj[prop];
      if (!seen.has(value)) {
        seen.add(value);
        return true;
      }
      return false;
    });
  }


  useEffect(() => {
    const arr = [];
    if (entertainment?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("1")
      arr.push(...entertainment?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (lifestyle?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("2")
      arr.push(...lifestyle?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (world?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("3")
      arr.push(...world?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (business?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("4")
      arr.push(...business?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (sports?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("5")
      arr.push(...sports?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (technology?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("6")
      arr.push(...technology?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (science?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("7")
      arr.push(...science?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }

    if (topnews?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()))?.length > 0) {
      console.log("8")
      arr.push(...topnews?.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase())));
    }
    const unique = getUniqueObjects(arr, 'title');
    setNews(unique);
  }, [search, entertainment, lifestyle, world, business, sports, technology, science, topnews])
  return (
    <>
      <Layout>
        <Box mx={{ md: 15, xs: 5 }} mb={10}>
          <Box mx={{ md: 15, xs: 5 }} mb={10}>
            <Box my={10}>
              <Typography className='h1 bold' sx={{ my: 4 }} >
                News
              </Typography>
              <Box>
                <Grid container spacing={4}>

                  {news && news.length > 0 ?
                    news.slice(0, 5).map((data) => (
                      <Grid item md={12}>
                        <SearchNewsCard
                          title={data.title}
                          cover={data?.images?.original}
                          time={data?.timestamp}
                          desc={data?.text}
                          catagory={data?.category}
                          url={encodeURIComponent(data?.newsUrl)}
                        />

                      </Grid>
                    ))
                    :
                    <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                  }
                </Grid>
              </Box>
              {/* <Box my={8}>
                <Pagination count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="secondary"
                  size="large"
                  shape="rounded" />
              </Box> */}
            </Box>
            {/* <Box my={10}>
            <Typography className='h1 bold' sx={{ my: 4 }} >
              Videos
            </Typography>
            <Box>
              <Grid container spacing={4}>
  
                {videoNews.length > 0 ? videoNews.slice(0, 3).map((data) => (
                  <Grid item md={12}>
                    <SearchVideoCard
                      title={data.title}
                      cover={data?.images.original}
                      time={data?.timestamp}
                      desc={data?.text}
                      catagory={category}
                      url={encodeURIComponent(data?.newsUrl)}
                    />

                  </Grid>
                ))
                  :
                  <Grid item md={12}>                   
                    <Typography className='text1' align='center'>
                      No News Found
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Box>
            {videoNews.length > 0 ?
              <Box my={8}>
                <Pagination
                  count={totalVideoPages}
                  page={currentVideoPage}
                  onChange={(event, page) => setCurrentVideoPage(page)}
                  color="secondary"
                  size="large"
                  shape="rounded" />
              </Box>
              :
              ""
            }
          </Box> */}
            <PurchaseNowCard />
          </Box>
        </Box>
      </Layout>
    </>
  )
}
