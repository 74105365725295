import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import MihoTv from './Pages/MihoTv';
import AboutUs from './Pages/AboutUs';
import RadioLive from './Pages/RadioLive';
import MihoSearch from './Pages/MihoSearch';
import BlogDetailsPage from './Pages/BlogDetailsPage';
import { getNews, getRadioNews, getTopNews, getTopicWiseNews } from './actions/news';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewsList from './Pages/NewsList';
import ContactUs from './Pages/ContactUs';

function App() {
const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getTopicWiseNews({
        topic: "business"
      }, (data) => {
        dispatch({
          type: 'ADD_BUSINESS',
          payload: data.data.data.items,
        }); 
      })
    )

    dispatch(
      getTopicWiseNews({
        topic: "sports"
      }, (data) => {
        dispatch({
          type: 'ADD_SPORTS',
          payload: data.data.data.items,
        }); 
      })
    )

    dispatch(
      getTopicWiseNews({
        topic: "health"
      }, (data) => {
        dispatch({
          type: 'ADD_LIFESTYLE',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getTopicWiseNews({
        topic: "international"
      }, (data) => {
        dispatch({
          type: 'ADD_WORLD',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getTopicWiseNews({
        topic: "entertainment"
      }, (data) => {
        dispatch({
          type: 'ADD_ENTERTAINMENT',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getTopicWiseNews({
        topic: "technology"
      }, (data) => {
        dispatch({
          type: 'ADD_TECHNOLOGY',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getTopicWiseNews({
        topic: "science"
      }, (data) => {
        dispatch({
          type: 'ADD_SCIENCE',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getTopicWiseNews({
        topic: "health"
      }, (data) => {
        dispatch({
          type: 'ADD_HEALTH',
          payload: data.data.data.items,
        }); 
      })
    );

    dispatch(
      getRadioNews( {}, (data) => {   
        dispatch({
          type: 'ADD_RADIO',
          payload: data.data.data,
        }); 
        console.log(data.data.data)
      })
    );

    dispatch(
      getTopicWiseNews({
      }, (data) => {
        dispatch({
          type: 'ADD_TOPNEWS',
          payload: data.data.data.items,
        }); 
      })
    );
    

  }, []);


  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/miho-tv" element={<MihoTv />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/radio-live" element={<RadioLive />} />
        <Route path="/search/:search" element={<MihoSearch />} />
        <Route path="/news-list/:category" element={<NewsList />} />
        <Route path="/detailspage/:url" element={<BlogDetailsPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
