export const bannerData = [
    {
      id: 1,
      cover: "../images/Image.png",
      catgeory: "Bussiness",
      title: "After all is said and done, more is done",
      authorName: "Craig Bator",
      time: "27 Dec 2020",
      desc:"We have a number of different teams within our agency that specialise in different areas of business so you can be sure that you won’t receive a generic service and although we can’t boast years and years of service we can ensure you that is a good thing in this industry."
    },
    {
      id: 2,
      cover: "../images/Image2.png",
      catgeory: "Bussiness",
      title: "Swiss authorities say Uber drivers should",
      authorName: "Craig Bator",
      time: "27 Dec 2020",
      desc:'Starting a new company is easy'
    },
    {
      id: 3,
      cover: "../images/Image1.png",
      catgeory: "Bussiness",
      title: "They’re back! Kennedy Darling named to return to",
      authorName: "Craig Bator",
      time: "27 Dec 2020",
      desc:'Starting a new company is easy'
    },
    {
      id: 4,
      cover: "../images/Image3.png",
      catgeory: "Bussiness",
      title: "Tourism in Dubai is booming by international tourist",
      authorName: "Craig Bator",
      time: "27 Dec 2020",
      desc:'Starting a new company is easy Starting a new company is easy'
    },
]

export const DontMissData = [
  {
    id:1,
    cover: "../images/news1.png",
    title: "Penn’s expanding political climate gears up for 2020 election",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:2,
    cover: "../images/news2.png",
    title: "Things to Look For in a Financial Trading  Platform",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:3,
    cover: "../images/news3.png",
    title: "The only thing that overcomes hard luck is hard work",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:4,
    cover: "../images/news4.png",
    title: "Success is not a good teacher failure makes you humble",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:5,
    cover: "../images/news5.png",
    title: "At Value-Focused Hotels, the Free Breakfast Gets Bigger",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
]


export const SportsData = [
  {
    id:1,
    cover: "../images/sports1.png",
    title: "South Africa hammer injury-hit Sri Lanka Player",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:2,
    cover: "../images/sports2.png",
    title: "Southee-lead New Zealand have Pakistan on the ropes",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:3,
    cover: "../images/sports3.png",
    title: "The only thing that overcomes hard luck is hard work",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:4,
    cover: "../images/sports4.png",
    title: "Success is not a good teacher failure makes you humble",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:5,
    cover: "../images/sports5.png",
    title: "Rahane-led India bury ghosts of Adelaide at Melbourne",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
]



export const LifeStyleData = [
  {
    id:1,
    cover: "../images/lifestyle1.png",
    title: "Best things you can do on a solo mountain climb",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:2,
    cover: "../images/lifestyle2.png",
    title: "Creative decorationg with houseplants",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:3,
    cover: "../images/lifestyle3.png",
    title: "How to use basic design  principles your home",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
    id:4,
    cover: "../images/lifestyle4.png",
    title: "How to burn calories with pleasant activites",
    authorName: "Craig Bator",
    time: "27 Dec 2020",
  },
  {
  
  },
]