
import { ArrowForwardIos, CloseRounded, SegmentRounded } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Images/Logo.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Links = [
  {
    name: 'Home',
    path: '/',
  },

  {
    name: 'International',
    path: '/news-list/world',
  },

  {
    name: 'Sports',
    path: '/news-list/sports',
  },
 
  {
    name: 'Business',
    path: '/news-list/business',
  },
  {
    name: 'Technology',
    path: '/news-list/technology',
  },
  {
    name: 'Entertainment',
    path: '/news-list/entertainment',
  },
  {
    name: 'Lifestyle',
    path: '/news-list/lifestyle',
  },

  // {
  //   name: 'Pages',
  //   path: '',
  // },

  // {
  //   name: 'About us',
  //   path: '/about-us',
  // },


]
export default function MobileNavbar() {
  const [menuToggle, setMenuToggle] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="sticky" sx={{ display: { md: 'none', xs: 'block', }, mb: 5 }} >
        <Toolbar sx={{ bgcolor: '#fff' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img src={logo} height='50px' />
            </Link>
          </Box>
          <Box sx={{ display: { md: 'none', xs: 'block' } }}>
            <IconButton sx={{ color: '#CC0000', my: 1 }} onClick={() => setMenuToggle(!menuToggle)}>
              <SegmentRounded sx={{ fontSize: '2.5rem' }} color="white" />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={menuToggle}
        onClose={() => setMenuToggle(!menuToggle)}
        anchor="right"
        sx={{ display: { md: 'none', xs: 'block' } }}
      >
        <Box sx={{ minWidth: 200 }}>
          <Box sx={{ m: 2, textAlign: 'right' }}>
            <IconButton sx={{ color: '#CC0000' }} onClick={() => setMenuToggle(!menuToggle)}>
              <CloseRounded />
            </IconButton>
          </Box>
          <List >
            {Links.map((item) => (
              <>
                <Link to={item.path} passHref key={item.name}>
                  <ListItem>
                    <ListItemButton>
                      <Typography
                        sx={{ fontSize: '18px', fontWeight: 600, color: '#000', fontFamily: 'Work sans' }}
                        className={currentPath === item.path ? 'active' : ''}
                      >
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </Link>
              </>
            ))}

            <Box className={currentPath === "" ? 'primary-red' : ''} sx={{ mx: 3 }}>
              {/* <Link className='text-white h3 mid' to="">Pages</Link> */}
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  disableRipple
                  endIcon={<KeyboardArrowDownIcon />}
                  className='text-black text2 bold'
                  sx={{ textTransform: 'none', }}

                >
                  Pages
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Contact us <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>About us <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Archive <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Author <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px', borderBottom: '2px solid #FFFFFF', paddingBottom: '5px' }}>Photo Gallery <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                  <MenuItem className='dropdown-item' onClick={handleClose}>
                    <Typography sx={{ display: ' flex', justifyContent: 'space-between', width: '180px' }}>Video <span><ArrowForwardIos fontSize='16px' /></span></Typography>
                  </MenuItem>
                </Menu>
              </div>
            </Box>

            <Box px={2} py={2} mx={2} className={currentPath === "/about-us" ? 'primary-red' : ''}>
              <Link className='text-black text2 bold' to="/about-us">About us</Link>
            </Box>

          </List>
        </Box>
      </Drawer>
    </>
  );
}
