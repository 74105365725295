import React, { useEffect, useState } from "react";
import Heading from "../common/Heading";
import "../../styles/heading.css";
import NewsCard from "./cards/NewsCard";
import { Box, Grid, Typography, Divider, Skeleton } from "@mui/material";
import MainNewsCard from "./cards/MainNewsCard";
import { SportsData } from "../../data";
import cover from "../../Images/mainSports.png";
import UpdateCard from "./cards/UpdateCard";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
export default function Sports(props) {
  const [items, setIems] = useState(SportsData);
  const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const { sports, world, technology, lifestyle, health } = useSelector((state) => ({ ...state }));

  // const handleChange = () => {
  //   setIsAccordionOpen((prevOpen) => !prevOpen);
  // };

  const handlePageChange = (event, page) => {
    // Handle the page change here if needed
  };

  const categories = [
    {
      category: "Life Style",
      bgColor: "#F65050",
    },

    {
      category: "World",
      bgColor: "#A56CBD",
    },

    {
      category: "Health",
      bgColor: "#C23785",
    },

    {
      category: "Technology",
      bgColor: "#4C60F5",
    },
  ];
  useEffect(() => {
    AOS.init();
  }, [])

  const [openAccordions, setOpenAccordions] = useState([]);

  const handleChange = (index) => {
    // Toggle the state of the clicked accordion
    setOpenAccordions((prevOpenAccordions) => {
      const isOpen = prevOpenAccordions.includes(index);
      return isOpen
        ? prevOpenAccordions.filter((item) => item !== index)
        : [...prevOpenAccordions, index];
    });
  };

  return (
    <>
      <Grid data-aos="fade-right" container spacing={2}>
        <Grid item md={9}>
          <Box>
            <Heading name="Sports" />
            <Box mt={3}>
              <Grid container spacing={2} gap={3}>
                <Grid item md={5.5}>
                  {sports.length > 0 ? (
                    <MainNewsCard
                      cover={sports[0].images?.original}
                      title={sports[0].title}
                      authorName={sports[0]?.publisher}
                      catagory="sports"
                      time={sports[0]?.timeline}
                      desc={sports[0].snippet}
                      url={sports[0]?.newsUrl}
                    />
                  ) : (
                    <Box>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton height={100} />
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>

                    </Box>
                  )}
                </Grid>

                <Grid item md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: { md: "400px", xs: "100%" },
                      gap: { md: 0, xs: 1 },
                    }}
                  >
                    {sports.length > 0
                      ? sports
                        .slice(0, 5)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timeline}
                            category={"sports"}
                            url={data?.newsUrl}
                          />
                        ))
                      :
                      <Grid container spacing={2}>
                        {[...Array(5)].map(() => (
                          <>
                            <Grid item md={3} xs={3}>
                              <Box>
                                <Skeleton variant="rectangular" sx={{ width: "100%", height: "70px" }} />
                              </Box>
                            </Grid>
                            <Grid item md={9} xs={9}>
                              <Box sx={{ pt: 0.5 }}>
                                <Skeleton height={30} />
                                <Skeleton width="60%" />
                              </Box>
                            </Grid>
                          </>

                        ))}
                      </Grid>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item md={3}>
          <UpdateCard />
          <Typography className="text3 bold text-orange" mt={2}>
            Categories
          </Typography>
          <Grid container mt={2}>
            <Grid item md={4}>
              <Divider
                className="primary-orange"
                sx={{ height: "2px", opacity: "1" }}
              />
            </Grid>
            <Grid item md={8}>
              <Divider
                className="primary-orange"
                sx={{ height: "2px", opacity: "1" }}
              />
            </Grid>
          </Grid>

          <Grid container mt={2}>
            {/* <Grid item md={1}> <ArrowForwardIosOutlinedIcon className="text3 opacity2" /></Grid>
            <Grid item md={5}><Typography className="text2 mid">Life Style</Typography></Grid>
            <Grid item md={6}><Typography className="text3 mid primary-orange text-white" sx={{ width: 'fit-content', ml: 'auto', p: 0.5 }}>50</Typography></Grid> */}
            {categories.map((item, index) => (
              <div onClick={() => setCurrentCategory(item?.category)}>
                <Accordion
                  elevation={0}
                  expanded={openAccordions.includes(index)}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box
                      sx={{
                        width: "100% !important",
                        display: "flex",
                        justifyContent: "space-between !important",
                      }}
                    >
                      <Typography className="text1 mid">
                        {
                          isAccordionOpen ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <ExpandMoreIcon />
                          ) /* Show ExpandMoreIcon when open and ExpandLessIcon when closed */
                        }
                        {item.category}
                      </Typography>
                      <Typography
                        className="text3 mid text-white"
                        bgColor={item.bgColor}
                        sx={{
                          width: "fit-content",
                          p: 0.5,
                          mr: 0.5,
                          backgroundColor: item.bgColor,
                        }}
                      >
                        {item?.category == "Life Style" ?  lifestyle?.length : item?.category == "World" ? world?.length : item?.category == "Health" ? health?.length : item?.category == "Technology" ? technology?.length : ""}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {item?.category == "Life Style"
                    ?
                    lifestyle.length > 0
                      ? lifestyle
                        .slice(0, 3)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timestamp}
                            category={"Life Style"}
                            url={data?.newsUrl}
                          />
                        ))
                      : ""
                  :
                  item?.category == "World"
                    ?
                    world.length > 0
                      ? world
                        .slice(0, 3)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timestamp}
                            category={"World"}
                            url={data?.newsUrl}
                          />
                        ))
                      : ""
                  :
                  item?.category == "Health"
                    ?
                    health.length > 0
                      ? health
                        .slice(0, 3)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timestamp}
                            category={"Health"}
                            url={data?.newsUrl}
                          />
                        ))
                      : ""
                  :
                  item?.category == "Technology"
                    ?
                    technology.length > 0
                      ? technology
                        .slice(0, 3)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timestamp}
                            category={"Technology"}
                            url={data?.newsUrl}
                          />
                        ))
                      : ""
                  :
                  ""}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
