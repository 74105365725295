import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import DOMPurify from "dompurify";



const MainNewsCard = ({cover, catagory,  title, authorName, time, desc, url}) => {


  const sanitizedContent = DOMPurify.sanitize(desc);
  const limitedContent =
    sanitizedContent.length > 230
      ? sanitizedContent.substring(0, 230) + '...' // Add ellipsis
      : sanitizedContent;



  return (
    <>
    <Box  >
        <Grid container spacing={2}>
            <Grid item md={12}>  <Box >
        <Box sx={{width:'100%', height:'250px', zIndex:-1, position:'relative' }}>
          <img style={{height:'250px', width:'100%'}}  src={cover} alt='' />
          <Typography sx={{position:'absolute',px:1, backgroundColor:'#0b9931', top:15, left:15, fontSize:'12px', color:'#fff' }}>{catagory}</Typography>    
        </Box>
      </Box></Grid>
            <Grid item md={12}>  
             <Box >
          {/* <Box>
            <span className="card-author">{authorName} - {time}</span>          
          </Box> */}
          <Link to={`/detailspage/${encodeURIComponent(url)}`}>
            <Typography  className="h2"> {title} </Typography>       
          </Link>
          <div >
            
        <p className="mainCard-text"> {limitedContent} </p>
      </div>       
        </Box>
        </Grid>
            </Grid>      
    
   
      </Box>
    </>
  )
}

export default MainNewsCard
