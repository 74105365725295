import React, { useEffect, useState } from "react";
import RadioBanner from "../Components/radio live/RadioBanner";
import Layout from "../Components/Layout";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import PurchaseNowCard from "../Components/common/PurchaseNowCard";
import PodcastCard from "../Components/radio live/PodcastCard";
import img from '../Images/Video1.png'
import { useSelector } from "react-redux";
import VideoCard2 from "../Components/common/VideoCard2";

export default function RadioLive() {

  const { radio } = useSelector((state) => ({ ...state }));

  useEffect(() => {

  }, [radio])

  return (
    <>
      <Layout>
        <Box mx={{ md: 15, xs: 5 }} mb={10}>
          <RadioBanner />
          <Box my={10}>
            <Typography className='h1 bold' sx={{ my: 4 }} >
              Today’s Trending  Podcasts
            </Typography>
            <Box>
              <Grid container spacing={2}>
                {radio && radio.length > 0 ?
                  radio.slice(4, 7).map((data, index) => (
                    <Grid item md={3.5} key={index}>
                      <PodcastCard
                        cover={data?.favicon ? data?.favicon : img}
                        name={data?.name}
                        url={data?.url}
                        index={index}
                      /></Grid>
                  ))
                  : <>
                    <Grid item md={3.5}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                    <Grid item md={3.5}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                    <Grid item md={3.5}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                  </>
                }
              </Grid>
            </Box>
          </Box>


          <Box my={5}>
            <Typography className="video-heading" my={3}>
              Top 10 Channels
            </Typography>
            <Box>
              <Grid container spacing={4}>
                {radio && radio.length > 0 ?
                  radio.sort((item) => item.votes).slice(0, 4).map((data, index) => (
                    <Grid item md={3} key={index}>
                      <VideoCard2
                        cover={data?.favicon ? data?.favicon : img}
                        title={data?.name}
                        catagory="Radio"
                        authorName="Craig Bator"
                        time="27 Dec 2020"
                        url={data?.url}
                        index={index}
                      />
                    </Grid>
                  ))
                  :
                  <>
                    <Grid item md={3}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                    <Grid item md={3}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                    <Grid item md={3}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                    <Grid item md={3}>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                    </Grid>
                  </>
                }

              </Grid>
            </Box>
          </Box>
          <PurchaseNowCard />
        </Box>
      </Layout>
    </>
  );
}
