import React from 'react'
import { Box, Grid, Typography, Button } from "@mui/material";

function UpdateCard() {
    return (
        <Box>
            <Box  sx={{backgroundColor:'#DEDADA', p:4}}>
           <Typography className='text1 bold' sx={{textAlign:'center'}} >Get Latest Updates</Typography>
                <input
                    className='input-feild'
                    variant="outlined"
                    placeholder="Your email address"
                    style={{ margin: '8px 0px', textAlign: 'center' }}
                />

                <Button className='main-button bold' sx={{ width: '100%' }} >
                    Subscribe
                </Button>
            </Box>
        </Box>
    )
}

export default UpdateCard