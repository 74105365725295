import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";


const VideoCard = ({ cover, catagory, title, authorName, time, url }) => {
  return (
    <>
      <Box>
        <Link to={`/detailspage/${encodeURIComponent(url)}`}>
        <Grid container spacing={2}>
            <Grid item md={12}>  <Box >
        <Box sx={{width:'100%', height:'250px', zIndex:-1, position:'relative' }}>
          <img style={{height:'250px', width:'100%'}}  src={cover} alt='' />         
         <Typography sx={{position:'absolute',px:1, backgroundColor:'#0b9931', top:15, left:15, fontSize:'12px', color:'#fff' }}>{catagory}</Typography> 
         <Box sx={{position:'absolute',  top:'50%', left:'50%', transform:'translate(-50%, -50%)',  color:'#fff', }}>
         <BsPlayCircle size={40}/> 
         </Box>
           
        </Box>
      </Box></Grid>
            <Grid item md={12}>  
             <Box >
          <div>
            <Typography className="subText2">{authorName} - <span className="opacity2"> {time} </span> </Typography>          
          </div>
            <Typography  className="text1"> {title} </Typography>       
          <div >     
      </div>       
        </Box>
        </Grid>
            </Grid> 
            </Link>       
      </Box>
    </>
  )
}

export default VideoCard
