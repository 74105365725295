import React from "react";
import HomeBanner from "../Components/homepage/HomeBanner";
import DontMiss from "../Components/homepage/DontMiss";
import { Box } from "@mui/material";
import Sports from "../Components/homepage/Sports";
import LifeStyle from "../Components/homepage/LifeStyle";
import Layout from "../Components/Layout";
import VideoSection from "../Components/homepage/VideoSection";
import Entertainment from "../Components/homepage/Entertainment";


const Home = () => {

  return (
    <>
      <Layout>
        <Box mx={{ md: 15, xs: 3 }}>
          <HomeBanner />
          <DontMiss />
          <VideoSection />
          <Entertainment/>
          <Sports />
          <LifeStyle />
        </Box>
      </Layout>


    </>
  );
};

export default Home;
