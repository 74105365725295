import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout';
import { Box, Grid, Pagination, Skeleton, Typography } from '@mui/material';
import PurchaseNowCard from '../Components/common/PurchaseNowCard';
import SearchVideoCard from '../Components/miho search/SearchVideoCard';
import SearchNewsCard from '../Components/miho search/SearchNewsCard';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConvertMongoDate } from '../utils/common';

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [videoNews, setVideoNews] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentVideoPage, setCurrentVideoPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalVideoPages, setTotalVideoPages] = useState(0);
  const { entertainment, lifestyle, world, business, sports, technology } = useSelector((state) => ({ ...state }));

  const { category } = useParams();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };


  const itemsPerPage = 5; // Number of items to display per page



  // useEffect(() => {
  //   if (category.toLowerCase() == "entertainment") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = entertainment;
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }
  //   if (category.toLowerCase() == "sports") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = sports;
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }

  //   if (category.toLowerCase() == "lifestyle") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = lifestyle
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }
  //   if (category.toLowerCase() == "business") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = business;
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }

  //   if (category.toLowerCase() == "world") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = world;
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }
  //   if (category.toLowerCase() == "technology") {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     const totalVideoNews = technology;
  //     setVideoNews(totalVideoNews.slice(startIndex, endIndex))
  //     setTotalVideoPages(Math.ceil(totalVideoNews.length / itemsPerPage));
  //   }
  // }, [category, currentVideoPage])

  useEffect(() => {
    if (category.toLowerCase() == "entertainment") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = entertainment;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }
    if (category.toLowerCase() == "sports") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = sports;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }

    if (category.toLowerCase() == "lifestyle") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = lifestyle;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }
    if (category.toLowerCase() == "business") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = business;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }

    if (category.toLowerCase() == "world") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = world;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }
    if (category.toLowerCase() == "technology") {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const totalNews = technology;
      setNews(totalNews.slice(startIndex, endIndex));
      setTotalPages(Math.ceil(totalNews.length / itemsPerPage));
    }
  }, [category, currentPage])

  return (
    <>
      <Layout>
        <Box mx={{ md: 15, xs: 5 }} mb={10}>
          <Box my={10}>
            <Typography className='h1 bold' sx={{ my: 4 }} >
              News
            </Typography>
            <Box>
              <Grid container spacing={4}>

                {news && news.length > 0 ?
                  news.slice(0, 5).map((data) => (
                    <Grid item md={12}>
                      <SearchNewsCard
                        title={data.title}
                        cover={data?.images?.original}
                        time={data?.timestamp}
                        desc={data?.snippet}
                        catagory={category}
                        url={encodeURIComponent(data?.newsUrl)}
                      />

                    </Grid>
                  ))
                  : 
                  <Skeleton variant="rectangular"  sx={{ width:'100%', height:'250px', }}/>
                  }

              </Grid>
            </Box>

            <Box my={8}>
              <Pagination count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
                size="large"
                shape="rounded" />
            </Box>
          </Box>
          {/* <Box my={10}>
            <Typography className='h1 bold' sx={{ my: 4 }} >
              Videos
            </Typography>
            <Box>
              <Grid container spacing={4}>
  
                {videoNews.length > 0 ? videoNews.slice(0, 3).map((data) => (
                  <Grid item md={12}>
                    <SearchVideoCard
                      title={data.title}
                      cover={data?.images.original}
                      time={data?.timestamp}
                      desc={data?.text}
                      catagory={category}
                      url={encodeURIComponent(data?.newsUrl)}
                    />

                  </Grid>
                ))
                  :
                  <Grid item md={12}>                   
                    <Typography className='text1' align='center'>
                      No News Found
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Box>
            {videoNews.length > 0 ?
              <Box my={8}>
                <Pagination
                  count={totalVideoPages}
                  page={currentVideoPage}
                  onChange={(event, page) => setCurrentVideoPage(page)}
                  color="secondary"
                  size="large"
                  shape="rounded" />
              </Box>
              :
              ""
            }
          </Box> */}
          <PurchaseNowCard />
        </Box>
      </Layout>
    </>
  )
}

export default NewsList;