import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "../utils/api";
import endPoints from "../utils/endPoints";

export const getRadioNews = (payload, callBack) => {
    return (dispatch) => {
        getAPI(
            endPoints.GET_RADIO + "?country=United Kingdom",
            payload,
            (response) => {
                console.log('Response For get all radio news', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting radio news', error);
            },
        );
    };
}


export const getNews = (topic, payload, callBack) => {
    return (dispatch) => {
        getAPI(
            endPoints.GET_NEWS + "?topic=" + topic,
            payload,
            (response) => {
                console.log('Response For get all news', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting news', error);
            },
        );
    };
}
export const getTopNews = (payload, callBack) => {
    return (dispatch) => {
        getAPI(
            endPoints.GET_RECENT_NEWS,
            payload,
            (response) => {
                console.log('Response For get top news', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While getting top news', error);
            },
        );
    };
}

export const getTopicWiseNews = (payload, callBack) => {
    return (dispatch) => {
        getAPI(
            endPoints.GET_TOPIC,
            { ...payload },
            (response) => {
                console.log('Response For get topic wise news', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While get topic wise news', error);
            },
        );
    }
}

export const getNewsByURL = (payload, callBack) => {
    return (dispatch) => {
        getAPI(
            endPoints.GET_NEWS_BY_URL,
            { ...payload },
            (response) => {
                console.log('Response For get news by url', response);
                callBack(response);
            },
            (error) => {
                console.log('Error While get news by url', error);
            },
        );
    }
}






