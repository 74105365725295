import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { BiTime } from "react-icons/bi";
import facebook from '../../Images/Facebook.svg';
import twitter from '../../Images/Twitter.svg';
import instagram from '../../Images/Instagram.svg';
import youtube from '../../Images/Youtube.svg';
import tikTok from '../../Images/tiktok.svg';
import { useSelector } from "react-redux";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";

export default function Topbar() {
  const { topnews } = useSelector((state) => ({ ...state }))

  return (
    <>
      <Box sx={{ boxShadow: "0px 1px 10px 0px #F6505026", }}>
        <Box
          sx={{
            display: { md: "flex", xs: "none" },
            justifyContent: "space-between",
            alignItems: "center",
            mx: { md: 15, xs: 5 },
            mb: 3

          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TiWeatherPartlySunny size={18} />
              <Typography className="subText1">15° New York</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <BiTime size={18} />
              <Typography className="subText1">Wednesday, 10 January 2021</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography className="subText1" sx={{ bgcolor: "#CC0000", p: 1.5, color: '#fff' }}>Breaking News</Typography>
              <Swiper
                slidesPerView={1}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                style={{ width: "600px" }}
                modules={[Autoplay]}
                className="mySwiper">
                {topnews && topnews?.length && topnews.slice(0,5).map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link to={`/detailspage/${encodeURIComponent(item?.newsUrl)}`} className="link">
                    {item?.title?.length > 100 ? `${item?.title?.substring(0,100)}...` : item?.title}
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <a href="https://twitter.com/MiihoNews51434" target="_blank">
                <img src={twitter} alt="Image 2" width="38px" height="38px" style={{margin:'0px 10px'}}/>
              </a>
              <a href="https://instagram.com/miihoonews?igshid=NTc4MTIwNjQ2YQ==" target="_blank">
                <img src={instagram} alt="Image 3" width="38px" height="38px" style={{margin:'0px 10px'}}/>
              </a>
              <a href="https://www.tiktok.com/@miihonews" target="_blank">
                <img src={tikTok} alt="Image 4" width="38px" height="38px" style={{margin:'0px 10px'}}/>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
