import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

export default function LifeStyleNewsCard({ cover,  title, authorName, time, url }) {

  const sanitizedContent = DOMPurify.sanitize(title);
  const limitedContent =
    sanitizedContent.length > 80
      ? sanitizedContent.substring(0, 80) + '...' // Add ellipsis
      : sanitizedContent;




  return (
    <>
      <Box >
        <Grid container spacing={2}>
          <Grid item md={4} xs={4}>
            <Box textAlign={'end'}>
              <img
                style={{  width: "100%", height: "100px" }}
                src={cover}
                alt="img"
              />
            </Box>
          </Grid>
          <Grid item md={8} xs={8}>
            <Box>
              <Box sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', width:{md:'90%', xs:'100%'}}}>
                {/* <Box className="authorDate">
                  <span>{authorName} - {time}</span>
                </Box> */}
                <Link to={`/detailspage/${encodeURIComponent(url)}`}>
                <Typography  className="h2" >
                  {limitedContent}
                </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
