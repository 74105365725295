import { Box, Typography } from "@mui/material";
import React from "react";
import img from "../../Images/mainNews.png";

export default function OurPlatformsCard() {
  return (
    <>
      <Box className="trn-2" sx={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <img src={img} width={"100%"} alt="" />
          <Box
            sx={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.0490798) 0%, rgba(0, 0, 0, 0) 15.1%, rgba(0, 0, 0, 0.5) 100%)",
              width: "100%",
              height: "100%",
              left: 0,
              bottom: 0,
              position: "absolute",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                bottom: "15px",
                left: "20px",
                fontFamily: "Oswald",
                fontWeight: 600,
                fontSize: "2rem",
                lineHeight: "45px",
                color: "#fff",
              }}
            >
              News
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
