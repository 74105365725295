import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import img1 from "../../Images/Layer.png";

export default function ResourcesCard() {
  return (
    <>
      <Box>
        <Box>
          <Grid container>
            <Grid item md={9} xs={9}>
              <Box sx={{ border: "1px solid #D3D7DC", p: 1.5, height: "100%" }}>
                <Typography
                  sx={{
                    fontFamily: "Oswald",
                    fontSize: "10px",
                    fontWeight: 700,
                    lineHeight: "16px",
                    letterSpacing: "1.25px",
                    textAlign: "left",
                  }}
                >
                  About Miiho News
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Work Sans",
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#515767",
                    lineHeight: "19px",
                    textAlign: "left",
                  }}
                >
                  Learn more about our work and the team that makes it possible
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={3}>
              <Box
                sx={{
                  border: "1px solid #D3D7DC",
                  p: 1.5,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  borderLeft: "none",
                }}
              >
                <img
                  src={img1}
                  style={{ width: "100%", height: "auto" }}
                  alt="img"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
