import { Box, Grid } from '@mui/material'
import React from 'react'
import WhoWeAre from '../Components/about us/WhoWeAre'
import Resources from '../Components/about us/Resources'
import OurPlatforms from '../Components/about us/OurPlatforms'
import OurCoreValues from '../Components/about us/OurCoreValues'
import Subscribe from '../Components/common/Subscribe'
import Layout from '../Components/Layout'


export default function AboutUs() {
  return (
    <>
    <Layout>
        <Box mx={{ md: 15, xs: 5 }}>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <WhoWeAre/>
            <OurCoreValues/>
            <OurPlatforms/>
          </Grid>
          <Grid item md={3}>
            <Resources/>
            <Subscribe/>
          </Grid>
        </Grid>
      </Box>
      </Layout>
    </>
  )
}
