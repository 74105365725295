import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import img1 from '../../Images/follow1.png'
import img2 from '../../Images/follow2.png'
import img3 from '../../Images/follow3.png'
import img4 from '../../Images/follow4.png'
import img5 from '../../Images/follow5.png'
import icon from '../../Images/followicon.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function FollowUs() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
    <Box data-aos="zoom-in">
        <Box sx={{position:'relative', zIndex:-1}}>
            <Grid container>
                <Grid item md={2.4} xs={2.4}>
              <img src={img1} width={'100%'} alt='img'/>
                </Grid>
                <Grid item md={2.4} xs={2.4}>
              <img src={img2} width={'100%'} alt='img'/>
                </Grid>
                <Grid item md={2.4} xs={2.4}>
              <img src={img3} width={'100%'} alt='img'/>
                </Grid>
                <Grid item md={2.4} xs={2.4}>
              <img src={img4} width={'100%'} alt='img'/>
                </Grid>
                <Grid item md={2.4} xs={2.4}>
              <img src={img5} width={'100%'} alt='img'/>
                </Grid>
            </Grid>
            <Box>
                <Box sx={{  position:'absolute',top:'50%', left:'50%',transform: 'translate(-50%, -50%)', p:'5%', borderRadius:'50%', bgcolor:'#FF3737A3', }}>
                <img src={icon} width={'100%'} alt='img'/>
                </Box>
                <Typography className='text1 sub-bold text-white' sx={{ position:'absolute',top:'65%', left:'50%',transform: 'translate(-50%, -50%)', display:{md:'block', xs:'none'}}}>Follow Us</Typography>
            </Box>
        </Box>
    </Box>
    </>
  )
}
