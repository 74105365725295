import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function Subscribe() {
  return (
    <>
      <Box sx={{ textAlign: "center", bgcolor: "#DEDADA", mb:7 }}>
        <Box p={4}>
          <Typography
            sx={{
              fontFamily: "Oswald",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "29px",
              mb: 2,
            }}
          >
            Get Latest Updates
          </Typography>
          <form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                gap: 2,
              }}
            >
              <input
                style={{
                  padding: "10px",
                  textAlign: "center",
                  border: "none",
                  outline: "none",
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "19px",
                }}
                type="email"
                placeholder="Your email address"
              />
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Work Sans",
                  fontWeight: 700,
                  fontSize: "18px",
                  lineHeight: "29px",
                  bgcolor: "#CC0000",
                  borderRadius: "0px",
                }}
              >
                Subscribe
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}
