import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

export default function NewsCard({ cover, title, authorName, time, category, url }) {

  const sanitizedContent = DOMPurify.sanitize(title);
  const limitedContent =
    sanitizedContent.length > 100
      ? sanitizedContent.substring(0, 100) + '...' // Add ellipsis
      : sanitizedContent;

  return (
    <>
      <Box className="trn-2">
        <Grid container spacing={2}>
          <Grid item md={3} xs={3}>
            <Box textAlign={'end'}>
              <img
                style={{ width: "100%", height: "70px" }}
                src={cover}
                alt="img"
              />
            </Box>
          </Grid>
          <Grid item md={9} xs={9}>
            <Box py={'5px'}>
              <Box sx={{ minHeight:'80px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: { md: '90%', xs: '100%' } }}>
                {/* <Box className="authorDate">
                  <span>{authorName} - {time}</span>
                </Box> */}
                <Link to={`/detailspage/${encodeURIComponent(url)}`}>
                  <Typography variant="h6" className="news-title">
                    {limitedContent}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
