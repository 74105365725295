import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import VideoCard from '../common/VideoCard'
import img1 from '../../Images/Video1.png'
import img2 from '../../Images/Video2.png'
import img3 from '../../Images/Video3.png'

export default function TrendingVideos() {
  return (
    <>
    <Box my={5}>
<Typography className="h1 bold" my={3}>Today’s Trending  Videos</Typography>
<Box>
    <Grid container spacing={4}>
        <Grid item md={3}>
            <VideoCard
               cover={img1}
               title="At Value-Focused Hotels, the Free Breakfast Gets Bigger"
               catagory="Esport"
               authorName="Craig Bator"
               time="27 Dec 2020"
            />
            </Grid>    
        <Grid item md={3}>
            <VideoCard
               cover={img2}
               title="Failure is the condiment that gives success its flavor"
               catagory="Esport"
               authorName="Craig Bator"
               time="27 Dec 2020"
            />
            </Grid>    
        <Grid item md={3}>
            <VideoCard
               cover={img3}
               title="Les nouveaux maillots du Real Madrid pour la saison"
               catagory="Esport"
               authorName="Craig Bator"
               time="27 Dec 2020"
            />
            </Grid>    
        <Grid item md={3}>
            <VideoCard
               cover={img1}
               title="Les nouveaux maillots du Real Madrid pour la saison"
               catagory="Esport"
               authorName="Craig Bator"
               time="27 Dec 2020"
            />
            </Grid>    
    </Grid>
</Box>

    </Box>
    </>
  )
}
