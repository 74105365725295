import actionNames from "../utils/actionNames";

const initialState = {}

export const authReducer = (state= initialState, action) => {
    switch (action.type) {
        case actionNames.AUTH_REDUCER:
            return { ...state, ...action.payload }
        case actionNames.CLEAR_AUTH_REDUCER:
            return {}
        default:
            return state;
    }
}