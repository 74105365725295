import React, { useEffect, useState } from "react";
import Heading from "../common/Heading";
import "../../styles/heading.css";
import NewsCard from "./cards/NewsCard";
import { Box, Grid, LinearProgress, Skeleton, Typography } from "@mui/material";
import MainNewsCard from "./cards/MainNewsCard";
import { DontMissData } from "../../data";
import AOS from 'aos';
import 'aos/dist/aos.css';
import cover from "../../Images/mainNews.png";
import StayConnected from "./StayConnected";
import TrendingsTabs from "./TrendingsTabs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function DontMiss() {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);
  const headings = ["All", "Technology", "Lifestyle", "World"];
  const [selectedCategory, SetSelectedCategory] =useState("World")

  const { topnews, entertainment, lifestyle, world, business, sports, technology } = useSelector((state) => ({ ...state }));

  const Headings = [
    "All", "Travel", "Recepies", "World"
  ]
  useEffect(() => {
    AOS.init();
  }, [])
  useEffect(() => {
    if (selectedCategory == "Entertainment") {
      setNews(entertainment)
    }
    if (selectedCategory == "Sports") {
      setNews(sports)
    }

    if (selectedCategory == "Lifestyle") {
      setNews(lifestyle)
    }
   
    if (selectedCategory == "World") {
    console.log("yoo",world);
    setNews(world)
    }
    if (selectedCategory == "Technology") {
      setNews(technology)
    }
    if(selectedCategory == "All"){
      setNews(topnews)
    }
  }, [selectedCategory, topnews, entertainment, lifestyle, world, business, sports, technology])


  return (
    <>
      <Grid container spacing={2} id="dont-miss">
        <Grid item md={9} my={5}>
          <Box>
            {/* <Heading name="Don’t Miss" headings={Headings} more="true" /> */}
            <>
      <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:{md:'row', xs:'column'}, gap:2}}>
        <Box >
          <Typography className="heading-title">Don’t Miss</Typography>
        </Box>
        <Box sx={{display:'flex',  gap:1, height:'32px',}}>
          {headings?.map((item, index) => (
             <Link onClick={()=>SetSelectedCategory(item)} >  
            <Typography key={index} className={`category${item}`} >
              {item}
            </Typography>
            </Link>
          ))}
        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        sx={{ height: "2px", bgcolor: "#393939", mt:2 }}
        value={20}
      />
    </>

            <Box mt={3}>
              <Grid container spacing={2} gap={3}>
                <Grid item md={5.5}>
                  {news.length > 0 ? (
                    <MainNewsCard
                      cover={news[0]?.images?.original}
                      title={news[0]?.title}
                      authorName={news[0]?.publisher}
                      catagory="world"
                      time={news[0]?.timestamp}
                      desc={news[0]?.text}
                      url={news[0]?.newsUrl}
                    />
                  ) : (
                    <Box>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "250px" }}
                      />
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton height={100} />
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Box>
                  )}
                </Grid>

                <Grid item md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: { md: "400px", xs: "100%" },
                      gap: { md: 0, xs: 1 },
                    }}
                  >
                    {news.length > 0 ? (
                      news
                        .slice(1, 6)
                        .map((data) => (
                          <NewsCard
                            cover={data?.images?.original}
                            title={data?.title}
                            authorName={data?.publisher}
                            time={data?.timestamp}
                            category={"world"}
                            url={data?.newsUrl}
                          />
                        ))
                    ) : (
                      <Grid container spacing={2}>
                        {[...Array(5)].map(() => (
                          <>
                            <Grid item md={3} xs={3}>
                              <Box>
                                <Skeleton
                                  variant="rectangular"
                                  sx={{ width: "100%", height: "70px" }}
                                />
                              </Box>
                            </Grid>
                            <Grid item md={9} xs={9}>
                              <Box sx={{ pt: 0.5 }}>
                                <Skeleton height={30} />
                                <Skeleton width="60%" />
                              </Box>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3} my={5}>
          <TrendingsTabs />
        </Grid>
      </Grid>
    </>
  );
}
