import { Box, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter, FaBehance, FaDribbble } from "react-icons/fa";
import { RiTiktokFill } from 'react-icons/ri';
import adImg from '../../Images/socialAd.png'
import SubHeading from '../common/SubHeading';

export default function StayConnected() {
    return (
        <>
            <Box my={5}>
                <SubHeading name="Stay Connected" />
                <Grid container spacing={1} mt={2}>
                    <Grid item md={6} sm={4} xs={6}>
                        <Link href="https://www.tiktok.com/@miihonews" sx={{ "&:hover": { color: '#fff' }, textDecoration: 'none' }} target="_blank">
                            <Box sx={{ backgroundColor: '#000', width: '100%', color: '#fff', }}>
                                <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                    <RiTiktokFill size={15} />
                                    <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                        <Link href="https://instagram.com/miihoonews?igshid=NTc4MTIwNjQ2YQ==" sx={{ "&:hover": { color: '#fff' }, textDecoration: 'none' }} target="_blank">
                            <Box sx={{ backgroundColor: '#C23785', width: '100%', color: '#fff', }}>
                                <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                    <FaInstagram size={20} />
                                    <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                        <Box sx={{ backgroundColor: '#EF5043', width: '100%', color: '#fff', }}>
                            <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                <FaYoutube size={15} />
                                <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                        <Link href="https://twitter.com/MiihoNews51434" sx={{ "&:hover": { color: '#fff' }, textDecoration: 'none' }} target="_blank">
                            <Box sx={{ backgroundColor: '#42C0F5', width: '100%', color: '#fff', }}>
                                <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                    <FaTwitter size={15} />
                                    <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid>
                    {/* <Grid item md={6} sm={4} xs={6}>
                        <Box sx={{ backgroundColor: '#1B7BFD', width: '100%', color: '#fff', }}>
                            <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                <FaBehance size={15} />
                                <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={4} xs={6}>
                        <Box sx={{ backgroundColor: '#F7679D', width: '100%', color: '#fff', }}>
                            <Box sx={{ p: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                <FaDribbble size={15} />
                                <Typography sx={{ fontSize: '8px', width: '15px' }}>15000 Followers</Typography>
                            </Box>
                        </Box>
                    </Grid> */}

                    <Grid item md={12} sm={6} xs={12} mt={3}>
                        <Box sx={{ position: 'relative', zIndex: -1 }}>
                            <img src={adImg} style={{ width: '100%' }} alt='img' />
                            <Typography sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', fontSize: '24px' }}>Ad</Typography>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}
