import React, { useEffect, useState } from "react";
import Heading from "../common/Heading";
import "../../styles/heading.css";
import { Box, Grid, Skeleton } from "@mui/material";
import MainNewsCard from "./cards/MainNewsCard";
import { LifeStyleData } from "../../data";
import img1 from "../../Images/lifestyleMain1.png";
import img2 from "../../Images/lifestyleMain2.png";
import PurchaseNowCard from "../common/PurchaseNowCard";
import LifeStyleNewsCard from "./cards/LifeStyleNewsCard";
import { useSelector } from "react-redux";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function LifeStyle() {
  const [items, setIems] = useState(LifeStyleData);
  useEffect(() => {
    AOS.init();
  }, [])
  const { lifestyle } = useSelector((state) => ({ ...state }))

  return (
    <>
      <Grid data-aos="fade-left" container spacing={2}>
        <Grid item md={9}>
          <Box my={5}>
            <Heading name="Life Style" />
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  {lifestyle.length > 0 ? (
                    <MainNewsCard
                      catagory="lifestyle"
                      cover={lifestyle[0]?.images?.original}
                      title={lifestyle[0]?.title}
                      authorName={lifestyle[0]?.publisher}
                      time={lifestyle[0]?.timeline}
                      desc={lifestyle[0]?.snippet}
                      url={lifestyle[0]?.newsUrl}
                    />
                  ) : (
                    <Box>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton height={100} />
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>

                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                      mt: 3
                    }}
                  >
                    {lifestyle.length > 0 ? (
                      <LifeStyleNewsCard
                        cover={lifestyle[1]?.images?.original}
                        title={lifestyle[1]?.title}
                        authorName={lifestyle[1]?.publisher}
                        time={lifestyle[1]?.timeline}
                        url={lifestyle[1]?.newsUrl}
                      />
                    ) : (
                      <Grid container spacing={2}>

                        <>
                          <Grid item md={3} xs={3}>
                            <Box>
                              <Skeleton variant="rectangular" sx={{ width: "100%", height: "70px" }} />
                            </Box>
                          </Grid>
                          <Grid item md={9} xs={9}>
                            <Box sx={{ pt: 0.5 }}>
                              <Skeleton height={30} />
                              <Skeleton width="60%" />
                            </Box>
                          </Grid>
                        </>
                      </Grid>
                    )}
                  </Box>
                </Grid>

                <Grid item md={6}>
                  {lifestyle.length > 0 ? (
                    <MainNewsCard
                      catagory="lifestyle"
                      cover={lifestyle[2]?.images?.original}
                      title={lifestyle[2]?.title}
                      authorName={lifestyle[2]?.publisher}
                      time={lifestyle[2]?.timeline}
                      desc={lifestyle[2]?.snippet}
                      url={lifestyle[2]?.newsUrl}
                    />
                  ) : (
                    <Box>
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: '250px', }} />
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton height={100} />
                        <Skeleton />
                        <Skeleton width="60%" />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      mt: 3
                    }}
                  >
                    {lifestyle.length > 0 ? (
                      <LifeStyleNewsCard
                        cover={lifestyle[3]?.images?.original}
                        title={lifestyle[3]?.title}
                        authorName={lifestyle[3]?.publisher}
                        time={lifestyle[3]?.timeline}
                        url={lifestyle[3]?.newsUrl}
                      />
                    ) : (
                      <Grid container spacing={2}>
                        <>
                          <Grid item md={3} xs={3}>
                            <Box>
                              <Skeleton variant="rectangular" sx={{ width: "100%", height: "70px" }} />
                            </Box>
                          </Grid>
                          <Grid item md={9} xs={9}>
                            <Box sx={{ pt: 0.5 }}>
                              <Skeleton height={30} />
                              <Skeleton width="60%" />
                            </Box>
                          </Grid>
                        </>
                      </Grid>
                    )}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <PurchaseNowCard />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
    </>
  );
}
