import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function PurchaseNowCard() {
  return (
    <>
      <Box className="purchaseNowCard" my={5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "space-between",
            alignItems: "center",
            p: 4,
            gap: { md: 0, xs: 2 },
          }}
        >
          <Box sx={{textAlign:{md:'start', xs:'center'}}}>
            <Typography
              sx={{
                fontFamily: "Work Sans",
                fontSize: "14px",
                fontWeight: 400,
                color: "#fff",
                maxWidth: "280px",
              }}
            >
              Best Selling <span style={{ fontWeight: 700 }}> BLOG</span> and{" "}
              <span style={{ fontWeight: 700 }}>MAGAZINE</span> Theme of All
              Time
            </Typography>
            <Typography
              sx={{
                fontFamily: "Oswald",
                fontSize: "14px",
                fontWeight: 400,
                color: "#CC0000",
                maxWidth: "280px",
              }}
            >
              Experience the change!
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#CC0000",
                borderRadius: 0,
                fontFamily: "Work Sans",
                fontWeight: 700,
              }}
            >
              PURCHASE NOW
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
