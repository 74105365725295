import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { BsCalendar2, BsPauseCircle, BsPlayCircle } from "react-icons/bs";
import ReactHlsPlayer from 'react-hls-player';

export default function PodcastCard({cover, name, url, index}) {
  const [currentIndex, setCurrentIndex] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleTogglePlay = () => {
        setCurrentIndex(index);
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        const videoPlayer = document.getElementById(index);
        if (videoPlayer) {
            if (isPlaying) {
                videoPlayer.play();
            } else {
                videoPlayer.pause();
            }
        }
    }, [isPlaying]);

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const handlePause = () => {
        setIsPlaying(false);
    };
  
  return (
    <>
     <Box  onClick={()=>handleTogglePlay(index)}>
        <Box className="trn-1" sx={{position:'relative', zIndex:-1}}>
            <img src={cover} alt='img' width={'100%'} height={'300px'} />
            <Box sx={{position:'absolute', bottom:'20px',width:'100%', }}>
            <Box sx={{  display:'flex', justifyContent:'space-between', px:3}}> 
                <Box>
                    <Typography className='text3 text-white'><u>{name}</u></Typography>
                    <Typography className='subText2 text-white' pt={1}> <BsCalendar2/> 20 Oct 2021</Typography>
                </Box>
                <Box sx={{ cursor:'pointer', color:'#fff' }}>                 
                {currentIndex === index && isPlaying ? <BsPauseCircle size={40} /> : <BsPlayCircle size={40} />}
                </Box>
                </Box>
            </Box>
        </Box>
                
        {url &&
                <ReactHlsPlayer
                    id={'p' + index}
                    src={url}
                    width="100%"
                    height="auto"
                    onPlay={handlePlay}
                    onPause={handlePause}
                    style={{ display: 'none' }}
                />
            }
    </Box>
    </>
  )
}
