import React, { useState } from "react";
import { Box, Grid, Typography, Skeleton } from "@mui/material";
import NewsCard from "../homepage/cards/NewsCard";
import { useSelector } from "react-redux";

function TrendingsTabs() {
  const [activeTab, setActiveTab] = useState(1);
  const { topnews } = useSelector((state) => ({ ...state }));

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box
          value={1}
          onClick={() => setActiveTab(1)}
          sx={{
            width: "100%",
            borderBottom:
              activeTab === 1 ? "2px solid #FE4A51" : "2px solid #000",
          }}
        >
          <Typography
            className="text3 bold cursor"
            sx={{ py: 1, color: activeTab === 1 ? "#FE4A51" : "#000" }}
          >
            Recents
          </Typography>
        </Box>

        <Box
          value={2}
          onClick={() => setActiveTab(2)}
          sx={{
            width: "100%",
            borderBottom:
              activeTab === 2 ? "2px solid #FE4A51" : "2px solid #000",
          }}
        >
          <Typography
            className="text3 bold cursor"
            sx={{ py: 1, color: activeTab === 2 ? "#FE4A51" : "#000" }}
          >
            Trending
          </Typography>
        </Box>

        <Box
          value={3}
          onClick={() => setActiveTab(3)}
          sx={{
            width: "100%",
            borderBottom:
              activeTab === 3 ? "2px solid #FE4A51" : "2px solid #000",
          }}
        >
          <Typography
            className="text3 bold cursor"
            sx={{ py: 1, color: activeTab === 3 ? "#FE4A51" : "#000" }}
          >
            Most Views
          </Typography>
        </Box>
      </Box>

      {activeTab == 1 ? (
        <>
          {topnews.length > 0 ? (
            topnews
              .slice(0, 5)
              .map((data) => (
                <NewsCard
                  cover={data?.images?.original}
                  title={data?.title}
                  authorName={data?.publisher}
                  time={data?.timestamp}
                  category={"topnews"}
                  url={data?.newsUrl}
                />
              ))
          ) : (
            <Grid container spacing={2}>
              {[...Array(5)].map(() => (
                <>
                  <Grid item md={3} xs={3}>
                    <Box>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "70px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton height={30} />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          )}
        </>
      ) : activeTab == 2 ? (
        <>
          {" "}
          {topnews.length > 0 ? (
            topnews
              .slice(5, 10)
              .map((data) => (
                <NewsCard
                  cover={data?.images?.original}
                  title={data?.title}
                  authorName={data?.publisher}
                  time={data?.timestamp}
                  category={"topnews"}
                  url={data?.newsUrl}
                />
              ))
          ) : (
            <Grid container spacing={2}>
              {[...Array(5)].map(() => (
                <>
                  <Grid item md={3} xs={3}>
                    <Box>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "70px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton height={30} />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          )}
        </>
      ) : (
        <>
          {" "}
          {topnews.length > 0 ? (
            topnews
              .slice(10, 15)
              .map((data) => (
                <NewsCard
                  cover={data?.images?.original}
                  title={data?.title}
                  authorName={data?.publisher}
                  time={data?.timestamp}
                  category={"topnews"}
                  url={data?.newsUrl}
                />
              ))
          ) : (
            <Grid container spacing={2}>
              {[...Array(5)].map(() => (
                <>
                  <Grid item md={3} xs={3}>
                    <Box>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "70px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={9} xs={9}>
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton height={30} />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default TrendingsTabs;
