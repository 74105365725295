import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import { Box, Skeleton, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import img from "../Images/socialAd.png";
import img1 from "../Images/Videomain.png";
import { FaRegCommentAlt, FaRegHeart } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewsByURL } from "../actions/news";


export default function BlogDetailsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, [])

  const [news, setNews] = useState();
  const contentType = "video";

  const { url } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNewsByURL({ uri: decodeURIComponent(url) }, (data) => {
      setNews(data.data.data);
    }))
  }, [url])


  const opts = {
    height: '315',
    width: '560',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo(); // You can control the video here
  };

  return (
    <>
      <Layout>
        <Box mx={{ md: 15, xs: 5 }}>
          <Box my={5}>
            <Box>
              <Box>
                <Box>
                  <Typography className="subText2 mid">
                    {/* {topic} */}
                    {news?.createdAt ?
                      <span style={{ opacity: 0.6 }}>-{" "} {new Date(news?.createdAt)}</span>
                      : ""}
                  </Typography>
                </Box>
                {news?.title ?
                  <Typography className="h1 sub-bold">
                    {news?.title}
                  </Typography>
                  :
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton height={100} />
                  </Box>
                }

                {
                  news?.authors?.length > 0
                    ?
                    <Stack
                      direction="row"
                      sx={{ alignItems: "center", mt: 1 }}
                      spacing={2}
                    >
                      <Avatar alt="" src={img} sx={{ width: 45, height: 45 }} />

                      <Typography className="reporterName">
                        Cameron Williamson
                      </Typography>
                    </Stack>
                    :
                    ""
                }
                {/* <Box
                  sx={{ display: "flex", alignItems: "center", gap: 3, mt: 5 }}
                >
                  <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                    <FaRegHeart color="#109BE9" fontSize="1.5em" />
                    <Typography>521K</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                    <FaRegCommentAlt color="#109BE9" fontSize="1.5em" />
                    <Typography>213</Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                    <FiShare2 color="#109BE9" fontSize="1.5em" />
                    <Typography>80</Typography>
                  </Box>
                </Box> */}
              </Box>
            </Box>

            <Box mt={2}>
              {
                news?.movies ?
                  news?.movies && news?.movies.length > 0 ?

                    // <MediaPlayer
                    //   title="Sprite Fight"
                    //   src={news?.movies[0]}
                    //   poster={img1}
                    //   aspectRatio={16 / 9}
                    //   crossorigin=""
                    // >
                    //   <MediaOutlet>
                    //     <MediaPoster alt="img" />
                    //   </MediaOutlet>
                    //   <MediaCommunitySkin />
                    // </MediaPlayer>
                    <Box sx={{ width: '100%', height: { md: '90vh', xs: '100%' } }}>
                      <iframe
                        width="100%"
                        height="100%"
                        src={news?.movies[0]}
                        title="YouTube Video Embed"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </Box>
                    :

                    <Box sx={{ width: '100%', height: { md: '90vh', xs: '100%' } }}>
                      <img src={news?.images} height={'90%'} width={'100%'} alt="img" style={{ objectFit: 'cover' }} />
                    </Box>
                  :

                  <Skeleton variant="rectangular" sx={{ height: { md: "100%", xs: "45vh" } }} />

              }
              <Box sx={{ color: "#515151" }}>
                {news?.text ?
                  <Typography className="text2" mt={3}>
                    {news?.text}
                  </Typography>
                  :
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton height={400} />
                    <Skeleton height={100} />
                    <Skeleton width="60%" />
                  </Box>}

              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
}
