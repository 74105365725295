import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, IconButton, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export default function Heading({ name, headings, more, next, prew }) {
  
  return (
    <>
      <Box sx={{display:'flex', justifyContent:'space-between', flexDirection:{md:'row', xs:'column'}, gap:2}}>
        <Box >
          <Typography className="heading-title">{name}</Typography>
        </Box>
        <Box sx={{display:'flex',  gap:1, height:'32px',}}>
          {headings?.map((item, index) => (
            <Typography key={index} className="category">
              {item}
            </Typography>
          ))}
          {
            more == 'true' ? 
          <select className="category primary-red">
            <option value="">More</option>
            <option value="Music">Music</option>
            <option value="News">News</option>
          </select>        
          :""               
          }

{
next == 'true' ?
<IconButton  size="small" sx={{borderRadius:'0px',color:'#fff', ml:1, bgcolor:'#fa9696', '&:hover': { bgcolor:'#fa9696', }}}>
<NavigateBeforeIcon fontSize="inherit" />
</IconButton>
:""
}
{
prew == 'true' ?
<IconButton  size="small" sx={{borderRadius:'0px', ml:1, color:'#fff', bgcolor:'#f65050',  '&:hover': { bgcolor:'#f65050', } }}>
<NavigateNextIcon fontSize="inherit" />
</IconButton>
:""
}




        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        sx={{ height: "2px", bgcolor: "#393939", mt:2 }}
        value={20}
      />
    </>
  );
}
