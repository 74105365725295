import React from "react";
import { Box } from "@mui/material";
import VideoDetails from "../Components/miho tv/VideoDetails";
import TrendingVideos from "../Components/miho tv/TrendingVideos";
import Layout from "../Components/Layout";



const MihoTv = () => {
  return (
    <>
       <Layout>
      <Box mx={{ md: 15, xs: 5 }}>
     <VideoDetails/>
     <TrendingVideos/>
      </Box>
      </Layout>
    </>
  );
};

export default MihoTv;
