import { Box, Typography } from "@mui/material";
import React from "react";
import '../../../styles/homeBanner.css'
import { Link } from "react-router-dom";
import { ConvertMongoDate, formatUTCDate } from "../../../utils/common";
import DOMPurify from "dompurify";


const BannerCard = ({ cover, category, categoryCss, title, classname, desc, date, authorName, url}) => {
    
  const sanitizedContent = DOMPurify.sanitize(desc);
    const limitedContent =
      sanitizedContent.length > 500
        ? sanitizedContent.substring(0, 500) + '...' // Add ellipsis
        : sanitizedContent;

  return (
    <>
      <Box className='banner-box' sx={{ position: "relative", p: "20px", height: "100%" }}>
        <Box className="img">
          <img
            src={cover}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -1,
            }}
            alt="img"
          />
        </Box>
        <Typography className={categoryCss}>{category}</Typography>
        <Box 
        className="banner-text"
          sx={{
            position: "absolute",
            bottom: "40px",
            width: "80%",
            transition: "0.4s ease",
          }}
        >
          {/* <Box className="text-white">
            <Typography className="subText1 mid text-white">
            {authorName} - <span className="opacity2s"> {formatUTCDate(date)} </span>{" "}
            </Typography>
          </Box> */}
          <Link to={`/detailspage/${encodeURIComponent(url)}`}>
          <Typography className={classname} sx={{ color: "#fff", width:'100%' }}>
            {title}
          </Typography>
          </Link>
          <Box className="hover_content">
            <Typography className="subText1">
                 {limitedContent}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BannerCard;
