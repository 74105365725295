import React, { useEffect, useState } from "react"
import Heading from "../common/Heading"
import { Box, Grid, Skeleton } from "@mui/material";
import NewsCard from "./cards/NewsCard";
import BannerCard from "./cards/BannerCard";
import img1 from '../../Images/entertainment.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector } from "react-redux";



export default function Entertainment() {
  useEffect(() => {
    AOS.init();
  }, [])

  const { entertainment } = useSelector((state) => ({ ...state }))

  return (
    <>
      <Box data-aos="flip-left">
        <Heading name="Entertainment" />
        <Grid container spacing={2} mb={5}>
          <Grid item md={12} xs={12} sx={{ height: '80vh', my: 2 }}>
            {entertainment.length > 0 ?
              <BannerCard
                cover={entertainment[0]?.images?.original}
                category="Entertainment"
                categoryCss="category1"
                title={entertainment[0]?.title}
                classname="h1"
                desc={entertainment[0]?.snippet}
                url={entertainment[0]?.newsUrl}
              />
              :
              <Skeleton variant="rectangular" sx={{ height: { md: "100%", xs: "45vh" } }} />
            }

          </Grid>


          {entertainment.length > 0 ?
            entertainment.slice(1, 4).map((data) => (
              <Grid item md={4} xs={12}>
                <NewsCard
                  cover={data?.images?.original}
                  title={data?.title}
                  authorName={data?.publisher}
                  time={data?.timeline}
                  category={"entertainment"}
                  url={data?.newsUrl}
                />
              </Grid>
            )) : ""
          }
        </Grid >
      </Box>
    </>
  )
}


