import { Box, Typography } from "@mui/material";
import React from "react";

export default function WhoWeAre() {
  return (
    <>
      <Box my={5}>
        <Typography className="h1 bold" >
          Who We Are
        </Typography>
        <Box
          sx={{
            color: "#515151",
            mt: 3,
          }}
        >
          <Typography className="text1">
            <span style={{color:'#1263D3'}}>Miiho News & World Report</span>
             is a multifaceted digital media company
            dedicated to helping consumers, business leaders and policy
            officials make important decisions in their lives. We use
            world-class data and technology to publish independent reporting,
            rankings, journalism and advice that has earned the trust of our
            readers and users for nearly 90 years. Our platforms on usnews.com
            include<span style={{color:'#1263D3'}}>Education, Health, Money, Travel, Cars, News, Technology and
            Space.</span> 
          </Typography>
          <Typography className="text1" mt={3} >
            We reach more than 40 million people monthly during moments when
            they are most in need of expert advice and motivated to act on that
            advice directly on our platforms. Our signature franchises include
            our “Best” series of consumer guides on colleges, graduate schools,
            hospitals, diets, cars, financial services and more. These guides
            provide an easy-to-digest list for consumers to better understand
            and compare when making their decisions. We continue to publish
            annual guides of the authoritative Best Colleges and Best Hospitals
            rankings on our website and in print. And our Miiho News Live
            flagship conferences highlight important national conversations
            including<span style={{color:'#1263D3'}}>Healthcare of Tomorrow</span>  and <span style={{color:'#1263D3'}}>Healthiest Communities.</span>
          </Typography>
          <Typography className="text1" mt={3} >
            We are proud to be one of the first legacy media brands to transform
            into a purely digital model nearly a dozen years ago. Our
            diversified business model engages consumers at every point in their
            decision-making journey and allows our corporate partners to use
            brand advertising, performance marketing, e-commerce, brand
            recognition and thought-leadership programs to achieve their
            business objectives.
          </Typography>
          <Typography className="text1" mt={3} >
            MIIho news is headquartered in Washington, D.C. with advertising,
            sales and corporate offices in New York and New Jersey. The company
            is privately owned by<span style={{color:'#1263D3'}}>Mortimer B. Zuckerman.</span> Mortimer B. Zuckerman. Our leadership team
            includes Eric Gertler serving as the Executive Chairman and Chief
            Executive Officer, William Holiber as the President & Vice Chairman,
            Kim Castro as the Editor & Chief Content Officer, and Neil
            Maheshwari as Chief Financial Officer and Chief Operating Officer.
            Learn more about<span style={{color:'#1263D3'}}>our leadership</span> .
          </Typography>
        </Box>
      </Box>
    </>
  );
}
