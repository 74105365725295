import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import img from "../../Images/socialAd.png";
import { FaRegCommentAlt, FaRegHeart } from "react-icons/fa";
import { BsCalendar2 } from "react-icons/bs";
import { FiShare2 } from "react-icons/fi";
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from '../../Images/podcast1.png'
import podcasticon from '../../Images/podcastlogo.svg'



export default function RadioBanner() {

  const [podcasts, setPodcasts] = React.useState('');

  const handleChange = (event) => {
    setPodcasts(event.target.value);
  };
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <Box my={5}>
        <Box data-aos="fade-left">
          <Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "Work Sans",
                  fontWeight: 400,
                  fontSize: "10px",
                }}
              >
                Entertainment -{" "}
                <span style={{ opacity: 0.6 }}> 27 Dec 2020</span>
              </Typography>
            </Box>
            <Typography className="video-heading">
            Mann Ki Baat Sarkar Ke Sath- By PM Modi
            </Typography>
            <Stack
              direction="row"
              sx={{ alignItems: "center", mt: 1 }}
              spacing={2}
            >
              <Avatar alt="" src={img} sx={{ width: 45, height: 45 }} />

              <Typography className="reporterName">
                Cameron Williamson
              </Typography>
            </Stack>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3, mt: 5 }}>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FaRegHeart color="#109BE9" fontSize="1.5em" />
                <Typography>521K</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FaRegCommentAlt color="#109BE9" fontSize="1.5em" />
                <Typography>213</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, textAlign: "center" }}>
                <FiShare2 color="#109BE9" fontSize="1.5em" />
                <Typography>80</Typography>
              </Box>
            </Box>
          </Box>
        </Box>


<Box sx={{display:'flex', justifyContent:'space-between', textAlign:'center', mt:4}}>
<Button variant="contained" sx={{bgcolor:'#CC0000',px:2, height:'40px'}}>Live Radio</Button>
<Box >
<FormControl sx={{ m: 1, minWidth: 120, background: '#E2D1FC', width:'150px' }} size="small">
        <Select
          value={podcasts}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="">
          Podcasts
          </MenuItem>
          <MenuItem value={1}>podcast1</MenuItem>
          <MenuItem value={2}>podcast2</MenuItem>
          <MenuItem value={3}>podcast3</MenuItem>
        </Select>
      
      </FormControl>
</Box>
</Box>


<Box data-aos="fade-down">
  <Box>
    <Grid container spacing={2}>
      <Grid item  md={4} >
        <Box sx={{position:'relative', zIndex:-1}}>
        <img src={img1} alt="img" width={'100%'}/>
        <Box sx={{position:'absolute',top:'50%', left:'50%',transform: 'translate(-50%, -50%)' , bgcolor:'#9043CF', px:1, py:.5, borderRadius:'10%',}}>                 
                    <img src={podcasticon} alt='' />   
                </Box>
        </Box>
      </Grid>
      <Grid item  md={6}>
        <Typography className="h2" mb={1}>  <u>Mann Ki Baat Sarkar Ke Sath </u></Typography>
          <Typography className="subText1"> <BsCalendar2/> 20 Oct 2021</Typography>
        <Typography className="subText1" my={1}>There's no better way to understand our world than through sports. Epic wins. Heart breaking losses. The feeling you get when you look a teammate in the eye. Sports reveal the strange and fascinating psychology that competition brings out in all of us. Good Sport dives into worlds like F1 racing, table tennis, NBA shooting, and beyond. Asking questions like: Is "the zone" a real place? What are talent hotbeds, and how do we create them? And… is it still baseball if you're wearing a giant banana suit? From host Jody Aviram (30 for 30, FiveThirtyEight, Radiotopia) and the TED Audio Collective, Good Sport is your guide through an array of stadiums, pitches, pools and slopes that shed a light on the ups and downs of being human.</Typography> 
      </Grid>
    </Grid>
  </Box>
  <Box>
    <Typography className="text2" my={3}>There's no better way to understand our world than through sports. Epic wins. Heart breaking losses. The feeling you get when you look a teammate in the eye. Sports reveal the strange and fascinating psychology that competition brings out in all of us. Good Sport dives into worlds like F1 racing, table tennis, NBA shooting, and beyond. Asking questions like: Is "the zone" a real place? What are talent hotbeds, and how do we create them? And… is it still baseball if you're wearing a giant banana suit? From host Jody Aviram (30 for 30, FiveThirtyEight, Radiotopia) and the TED Audio Collective, Good Sport is your guide through an array of stadiums, pitches, pools and slopes that shed a light on the ups and downs of being human.</Typography>
  </Box>
</Box>



      </Box>
    </>
  );
}
