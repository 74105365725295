import { Box, Grid, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React from "react";
import { Link } from "react-router-dom";


export default function SearchNewsCard({title, cover, time, desc, catagory, url}) {
  const sanitizedContent = DOMPurify.sanitize(desc);
  const limitedContent =
    sanitizedContent.length > 500
      ? sanitizedContent.substring(0, 500) + '...' // Add ellipsis
      : sanitizedContent;

      time = parseInt(time);

  return (
    <>
      <Box className="trn-3">
        <Grid container spacing={2} gap={5}>
          <Grid item md={5}>
            <img src={cover} width={'100%'} height={'100%'} alt="img" />
          </Grid>
          <Grid item md={6} >
          <Link to={`/detailspage/${encodeURIComponent(url)}`}>
            <Typography className="h2">
             {title}
            </Typography>
            </Link>
            <Typography className="subText1 mid" my={3}>{new Date(time).toDateString()}</Typography>
            <Typography className="text2 mid opacity2">
             {limitedContent}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
